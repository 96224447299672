var generic = generic || {};
var site = site || {};

(function ($) {
  $(document).ready(function () {
    var signed_in = parseInt(site.userInfoCookie.getValue('signed_in'));
    var is_loyalty_member = parseInt(site.userInfoCookie.getValue('is_loyalty_member'));

    if (signed_in && is_loyalty_member) {
      $('.smashcash').addClass('hidden');
      $('.smashcash_member').removeClass('hidden');
    } else {
      $('.smashcash_member').addClass('hidden');
      $('.smashcash').removeClass('hidden');
    }
  });
})(jQuery);
